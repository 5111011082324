import { useEffect, useRef, useState } from "react";
import useEditStore from "../../../../../store";
import IndicatorLine from "../../../../../assets/images/IndicatorLine.svg";
import IndicatorHand from "../../../../../assets/images/IndicatorHand.svg";
import {
  convertTimeObjectToSeconds,
  convertSecondsToHMS,
} from "../../../../../helperFunctions/timeConversion";
import { TimeIndicator } from "./Indicator.styled";
import { DraggableCore } from "react-draggable";
function Indicator({ framesContainer, mainContainerRef }) {
  const [isDragging, setDragging] = useState(false);
  const indicatorRef = useRef(null);
  const {
    currentTime,
    videoDuration,
    isVideoPlaying,
    setCurrentTime,
    setCurrentTimeUpdated,
    leftHiddenFramesRef,
    rightHiddenFramesRef, filmStripWidthPercent
  } = useEditStore();
  const navigateIndicatorToCurrentTime = () => {
    const indicator = indicatorRef.current;
    const mainContainer = mainContainerRef.current;
    if (framesContainer.current && indicator && !isDragging) {
      const totalWidthOfTheFrames =
        framesContainer.current.getBoundingClientRect().width;

      const currentTimeInSec = convertTimeObjectToSeconds(currentTime);
      const durationInSec = convertTimeObjectToSeconds(videoDuration);
      const leftPercent = (currentTimeInSec / durationInSec) * 100;
      const left = totalWidthOfTheFrames * (leftPercent / 100);
      const translateX = durationInSec === 0 ? -6.4 : left - 6.4;
      indicator.style.transform = `translate(${translateX}px, 0)`;

      if (!isVideoPlaying) indicator.style.transition = "none";
      else {
        indicator.style.transition = "transform 0.3s linear";
        //checking scrolling needed or not
        const indicatorRect = indicator.getBoundingClientRect();
        const mainContainerRect = mainContainer.getBoundingClientRect();
        if (
          window.innerWidth - indicatorRect.right < 20 ||
          indicatorRect.left < mainContainerRect.left ||
          indicatorRect.right > window.innerWidth
        ) {
          scrollTheMainContainer(translateX);
        }
      }
    }
  };

  const updateCurrentTimeToIndicator = (e, data) => {
    const indicator = indicatorRef.current;

    if (framesContainer.current && indicator) {
      const xLimit = leftHiddenFramesRef.current.offsetWidth;
      const yLimit =
        framesContainer.current.offsetWidth -
        rightHiddenFramesRef.current.offsetWidth - 6.4 - 2;

      const totalWidthOfTheFrames =
        framesContainer.current.getBoundingClientRect().width - 6.4;
      const value =
        data.x < xLimit ? xLimit : data.x > yLimit ? yLimit : data.x;
      indicator.style.transform = `translate(${value}px, 0)`;

      const indicatorPositionInPixels = value;
      const indicatorPositionInPercentage =
        (indicatorPositionInPixels / totalWidthOfTheFrames) * 100;
      const updatedCurrentTimeInSecs =
        convertTimeObjectToSeconds(videoDuration) *
        (indicatorPositionInPercentage / 100);
      const updatedCurrentTimeInHMS = convertSecondsToHMS(
        updatedCurrentTimeInSecs
      );
      setCurrentTime(updatedCurrentTimeInHMS);
    }
  };

  const handleStopDragging = (e, data) => {
    const indicator = indicatorRef.current;
    indicator.style.transition = isVideoPlaying
      ? `transform 0.3s linear`
      : "none";
    setDragging(false);
    setCurrentTimeUpdated(true);
  };

  const handleStartDragging = (e, data) => {
    const indicator = indicatorRef.current;
    indicator.style.transition = `none`;
    setDragging(true);
  };

  const scrollTheMainContainer = (to) => {
    if (mainContainerRef.current) {
      mainContainerRef.current.scrollLeft = to;
    }
  };

  useEffect(() => {
    navigateIndicatorToCurrentTime();
  }, [
    currentTime,
    isVideoPlaying,
    videoDuration,
    framesContainer.current,
    mainContainerRef.current,filmStripWidthPercent
  ]);

  return (
    <DraggableCore
      onStart={handleStartDragging}
      onDrag={updateCurrentTimeToIndicator}
      onStop={handleStopDragging}
      axis="x"
    >
      <TimeIndicator ref={indicatorRef}>
        <img
          draggable="false"
          id="indicator-hand"
          src={IndicatorHand}
          alt="hand"
        />
        <img
          draggable="false"
          id="indicator-line"
          src={IndicatorLine}
          alt="line"
        />
      </TimeIndicator>
    </DraggableCore>
  );
}

export default Indicator;
