import { styled } from "styled-components";

export const ToolsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 20px;
    left: 34px;
    gap: 24px;
`;

export const Tool = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const ToolsLabel = styled.img`
    width: 10px;
    height: 10px;
`;

export const ToolsName = styled.div`
  color: #404b59;
  font-family: Inter400;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;