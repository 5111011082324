import { CloseButton, DialogContainer, DialogContent } from "./Dialog.styled";
import close from "../../assets/images/close.svg"
import Save from "./Save";
import useEditStore from "../../store";
import Processing from "./Processing";
import { useRef, useState } from "react";

function Dialog() {
    const { isPublishClicked, setPublishClicked } = useEditStore()
    const [isSaveClicked, setSaveClicked] = useState(false)
    const closeButtonRef = useRef(null)
    if (!isPublishClicked) return

    return (
        <DialogContainer>
            <DialogContent>
                <CloseButton ref={closeButtonRef} hide={isSaveClicked ? true : false} src={close} alt="alt" onClick={() => setPublishClicked(false)} />
                {isSaveClicked ? <Processing closeButtonRef={closeButtonRef} setSaveClicked={setSaveClicked} /> : <Save setSaveClicked={setSaveClicked} />}
            </DialogContent>
        </DialogContainer>
    );
}

export default Dialog;