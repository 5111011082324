import React from "react";
import Grey from "./images/flonnect-logo.svg";
import Delete from "./images/deleteuser.svg";

import { Removed, RemovedNav, ContentDiv, InfoContainer } from "./index.styled";

const Removeduser = () => {
  return (
    <Removed>
      <RemovedNav
        onClick={() => {
          window.location.href = "https://app.flonnect.com/dashboard/library"
        }}
      >
        <img className="grey" src={Grey} alt="" draggable="false" />
      </RemovedNav>
      <ContentDiv>
        <InfoContainer>
          <div id="acc-img" >
            <img src={Delete} alt="" draggable="false" height="40px" />
          </div>
          <div className="info">Access denied to this recording</div>
          <div className="description">
            <div className="requestInfo">
              You can view recording once the creator grant you to view their
              file
            </div>
          </div>

        </InfoContainer>
      </ContentDiv>
    </Removed>
  );
};

export default Removeduser;
