import { ToolsContainer, ToolsLabel, ToolsName, Tool } from "./Tools.styled";
import undo from "../../../../../assets/images/undo.svg"
import redo from "../../../../../assets/images/redo.svg";

function Tools() {
    return (
      <ToolsContainer>
        <Tool>
          <ToolsLabel src={undo} alt="undo" />
          <ToolsName>Undo</ToolsName>
        </Tool>
        <Tool>
          <ToolsLabel src={redo} alt="redo" />
          <ToolsName>Redo</ToolsName>
        </Tool>
      </ToolsContainer>
    );
}

export default Tools;