import Layers from "./Layers/Layers.index";
import Preview from "./Preview/Preview.index";
import { EditingPanelContainer } from "./Editing.styled";
import useEditStore from "../../store";

function EditingPanel() {
  const { userDetails, videoDetails} = useEditStore()

  return (
    <EditingPanelContainer>
      {videoDetails ? <Preview /> : null}
      {userDetails ? <Layers /> : null}
    </EditingPanelContainer>
  );
}

export default EditingPanel;
