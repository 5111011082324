import { useRef } from "react";
import { NameContainer, NameLabel, SaveContent, Title, Name, LocationLabel, LocationContainer, LocationPath, SaveButton, ButtonText } from "./Dialog.styled";
import useEditStore from "../../store";
function Save({ setSaveClicked }) {
  const inputRef = useRef(null)
  const { setTrimmedVideoName } = useEditStore()
  const handleSave = () => {
    setSaveClicked(true)
  }

  const handleInputChange = (e) => {
    inputRef.current.value = e.target.value;
  }

  const handleSubmit = () => {
    if (inputRef.current.value !== "") setTrimmedVideoName(inputRef.current.value);
  }

  return (
    <SaveContent>
      <Title>Save video</Title>
      <NameLabel>Name</NameLabel>
      <NameContainer>
        <Name ref={inputRef} placeholder="Flonnect file name - edited" onChange={handleInputChange} onBlur={handleSubmit} />
      </NameContainer>
      <LocationLabel>Location</LocationLabel>
      <LocationContainer show={true} >
        <LocationPath >Library</LocationPath>
        <LocationPath> {`>`} </LocationPath>
        <LocationPath main={true} >My video Recorordings</LocationPath>
      </LocationContainer>
      <SaveButton onClick={handleSave}  >
        <ButtonText>Save</ButtonText>
      </SaveButton>
    </SaveContent>
  );
}

export default Save;