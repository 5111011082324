import {
  DraggingPointerContainer,
  LayerDetails,
  Image,
  SelectionContainer,
  Name,
  Details,
} from "./TrimLayer.styled";
import rightArrow from "../../../../../assets/images/rightArrow.svg";
import leftArrow from "../../../../../assets/images/leftArrow.svg";
import { DraggableCore } from "react-draggable";
import { useEffect, useRef, useState } from "react";
import layerLogo from "../../../../../assets/images/layerlogo.svg";
import useEditStore from "../../../../../store";
import {
  convertSecondsToHMS,
  convertTimeObjectToSeconds,
} from "../../../../../helperFunctions/timeConversion";
import { CurrentTime } from "../Controls/Controls.styled";
function Selection({
  leftPlaceHolderRef,
  rightPlaceHolderRef,
  mainContainerRef,
}) {
  const {
    framesContainerRef,
    setTrimPortion,
    trimPortion,
    videoDuration,
    filmStripWidthPercent,
    trimStartTime,
    setTrimStartTime,
    trimEndTime,
    setCurrentTime,
    setCurrentTimeUpdated,
    setTrimEndTime,
    isTrimUpdatedByInput,
    setTrimUpdatedByInput,
    leftHiddenFramesRef,
    rightHiddenFramesRef,
    clickedPixelOnTheRuler,
    setClickedPixelOnTheRuler,
  } = useEditStore();
  const [isDraggerOn, setDraggerOn] = useState(false);
  const mainLayerRef = useRef(null);
  const leftDraggerRef = useRef(null);
  const rightDraggerRef = useRef(null);
  const [rightConstant, setRightConstant] = useState();
  const [leftConstant, setLeftConstant] = useState();
  const [detailsContent, setDetailsContent] = useState();

  const getContent = () => {
    const mainLayer = mainLayerRef.current;
    if (!mainLayer) return;

    const details = [];
    const count = Math.floor((mainLayer.offsetWidth - 22) / (140 + 99));
    for (let i = 0; i < count; i += 1) {
      details.push(
        <LayerDetails>
          <Image draggable="false" src={layerLogo} alt="logo" />
          <Name>Trim Layer</Name>
        </LayerDetails>
      );
    }
    return details;
  };

  const calculateSecondsForPixel = (pixel) => {
    if (!framesContainerRef || !framesContainerRef.current) return;
    const framesContainerWidth = framesContainerRef.current.offsetWidth;
    return (
      convertTimeObjectToSeconds(videoDuration) * (pixel / framesContainerWidth)
    );
  };

  const calculatePixelsForSeconds = (seconds) => {
    const framesContainerWidth = framesContainerRef?.current?.offsetWidth;
    const result =
      (seconds / convertTimeObjectToSeconds(videoDuration)) *
      framesContainerWidth;
    return result;
  };

  const handleLayerDragging = (e, data) => {
    if (isDraggerOn) return;

    const mainLayer = mainLayerRef.current;
    const rightPlaceHolder = rightPlaceHolderRef.current;
    const leftPlaceHolder = leftPlaceHolderRef.current;

    const leftPlaceHolderWidth = leftPlaceHolder.getBoundingClientRect().width;
    const rightPlaceHolderWidth =
      rightPlaceHolder.getBoundingClientRect().width;
    if (e.movementX < 0)
      leftPlaceHolder.style.width = `${leftPlaceHolderWidth + e.movementX}px`;
    else {
      leftPlaceHolder.style.width = `${leftPlaceHolderWidth + e.movementX}px`;
      rightPlaceHolder.style.width = `${rightPlaceHolderWidth - e.movementX}px`;
    }
  };

  const handleLeftDragStart = (e, data) => {
    // setDraggerOn(true);
    const leftPlaceHolder = leftPlaceHolderRef.current;
    const mainLayer = mainLayerRef.current;
    const rightPlaceHolder = rightPlaceHolderRef.current;
    const leftPlaceHolderWidth = leftPlaceHolder.getBoundingClientRect().width;
    const mainLayerWidth = mainLayer.getBoundingClientRect().width;

    rightPlaceHolder.style.width = `calc(100% - ${leftPlaceHolderWidth}px - ${mainLayerWidth}px)`;
    setRightConstant(
      framesContainerRef.current.offsetWidth -
        leftPlaceHolderWidth -
        mainLayerWidth
    );
  };

  const handleLeftDragging = (e, data) => {
    if (e.clientX < 455) {
      return;
    }

    const leftPlaceHolder = leftPlaceHolderRef.current;
    const mainLayer = mainLayerRef.current;
    const rightPlaceHolder = rightPlaceHolderRef.current;

    const leftDragger = leftDraggerRef.current;
    if (leftPlaceHolder && mainLayer && leftDragger) {
      const leftPlaceHolderWidth =
        leftPlaceHolder.getBoundingClientRect().width;
      const rightPlaceHolderWidth = rightConstant;
      const mainLayerWidth = mainLayer.getBoundingClientRect().width;

      if (data.x > 0) {
        mainLayer.style.width = `calc(100% - ${
          leftPlaceHolderWidth + data.x
        }px - ${rightPlaceHolderWidth}px)`;

        leftPlaceHolder.style.width = `${leftPlaceHolderWidth + data.x}px`;
        leftHiddenFramesRef.current.style.width = `${
          leftPlaceHolderWidth + data.x
        }px`;
        rightHiddenFramesRef.current.style.width = `${rightConstant}px`;
      } else {
        mainLayer.style.width = `calc(100% - ${
          leftPlaceHolderWidth + data.x
        }px - ${rightPlaceHolderWidth}px)`;
        leftHiddenFramesRef.current.style.width = `${
          leftPlaceHolderWidth + data.x
        }px`;
        leftPlaceHolder.style.width = `${leftPlaceHolderWidth + data.x}px`;
        rightHiddenFramesRef.current.style.width = `${rightConstant}px`;
      }
    }
  };

  const handleRightDragStart = (e, data) => {
    // setDraggerOn(true);
    const rightPlaceHolder = rightPlaceHolderRef.current;
    const mainLayer = mainLayerRef.current;
    const leftPlaceHolder = leftPlaceHolderRef.current;
    const rightPlaceHolderWidth =
      rightPlaceHolder.getBoundingClientRect().width;
    const mainLayerWidth = mainLayer.getBoundingClientRect().width;
    leftPlaceHolder.style.width = `${
      framesContainerRef.current.offsetWidth -
      rightPlaceHolderWidth -
      mainLayerWidth
    }px`;
    setLeftConstant(
      framesContainerRef.current.offsetWidth -
        rightPlaceHolderWidth -
        mainLayerWidth
    );
  };

  const handleRightDragging = (e, data) => {
    if (e.clientX > window.innerWidth - 6.4) {
      return;
    }
    const rightPlaceHolder = rightPlaceHolderRef.current;
    const mainLayer = mainLayerRef.current;

    const rightDragger = rightDraggerRef.current;
    if (rightPlaceHolder && mainLayer && rightDragger) {
      const mainWidthUpdated = data.x;
      if (mainWidthUpdated < 12) return;
      mainLayer.style.width = `${mainWidthUpdated}px`;

      const rightUpdated =
        data.x > 0
          ? rightPlaceHolder.offsetWidth - data.deltaX
          : rightPlaceHolder.offsetWidth + data.deltaX;
      rightPlaceHolder.style.width = `${rightUpdated}px`;
      rightHiddenFramesRef.current.style.width = `${rightUpdated - 3.91 - 2}px`;
      leftPlaceHolderRef.current.style.width = `${leftConstant}px`;
      leftHiddenFramesRef.current.style.width = `${leftConstant}px`;
    }
  };

  const handleDragStop = () => {
    setRightConstant();
    if (framesContainerRef?.current) {
      // let starting = leftPlaceHolderRef.current.offsetWidth + 4;
      // starting = starting === 0 ? starting : starting + 4;
      const starting = leftConstant
        ? leftConstant
        : leftPlaceHolderRef.current.offsetWidth;
      const ending = starting + mainLayerRef.current.offsetWidth;
      setTrimPortion({
        start: calculateSecondsForPixel(starting),
        end: calculateSecondsForPixel(ending),
      });
      setTrimStartTime(convertSecondsToHMS(calculateSecondsForPixel(starting)));
      setTrimEndTime(convertSecondsToHMS(calculateSecondsForPixel(ending)));
      setLeftConstant();
    }
  };

  const calculateMainLayerWidthBasedOnTrimPortion = () => {
    const startTimeInsecs = trimPortion.start;
    const endTimeInSecs = trimPortion.end;
    const mainWidth =
      calculatePixelsForSeconds(endTimeInSecs) -
      calculatePixelsForSeconds(startTimeInsecs);
    return mainWidth
  }

  const positioningSelectionPart = () => {
    mainLayerRef.current.style.width = `${calculateMainLayerWidthBasedOnTrimPortion()}px`;

    if (trimPortion.start) {
      const leftWidth = calculatePixelsForSeconds(trimPortion.start);
      leftPlaceHolderRef.current.style.width = `${leftWidth}px`;
      if(leftHiddenFramesRef?.current) leftHiddenFramesRef.current.style.width = `${leftWidth}px`
    }
    rightPlaceHolderRef.current.style.width = `calc(100% - ${leftPlaceHolderRef.current.offsetWidth}px - ${mainLayerRef.current.offsetWidth}px)`;
    if(rightHiddenFramesRef?.current) rightHiddenFramesRef.current.style.width = `${rightPlaceHolderRef.current.offsetWidth}px`
  }


  useEffect(() => {
    if (filmStripWidthPercent && trimPortion.end != 0) {
      const pixelForStartTime = calculatePixelsForSeconds(trimPortion.start);
      const pixelForEndTime = calculatePixelsForSeconds(trimPortion.end);
      leftPlaceHolderRef.current.style.width = `${pixelForStartTime}px`;

      mainLayerRef.current.style.width = `${
        pixelForEndTime - pixelForStartTime
      }px`;
      rightPlaceHolderRef.current.style.width = `calc(100% - ${mainLayerRef.current.offsetWidth}px - ${leftPlaceHolderRef.current.offsetWidth}px)`;
      leftHiddenFramesRef.current.style.width = `${leftPlaceHolderRef.current.offsetWidth}px`;
      rightHiddenFramesRef.current.style.width = `${rightPlaceHolderRef.current.offsetWidth}px`;
    }
  }, [filmStripWidthPercent]);

  useEffect(() => {
    if (rightHiddenFramesRef?.current)
      rightHiddenFramesRef.current.style.width = `${rightPlaceHolderRef.current.offsetWidth}px`;
  }, [
    rightPlaceHolderRef?.current?.offsetWidth,
    rightHiddenFramesRef?.current,
  ]);

  useEffect(() => {
    mainLayerRef.current.style.width = `${calculateMainLayerWidthBasedOnTrimPortion()}px`;
    setCurrentTime(convertSecondsToHMS(trimPortion.start));
    setCurrentTimeUpdated(true);
  }, [trimPortion]);

  useEffect(() => {
    positioningSelectionPart()
    setTrimUpdatedByInput(false);
  }, [isTrimUpdatedByInput]);

  useEffect(() => {
    if (
      mainLayerRef.current &&
      leftPlaceHolderRef.current &&
      rightPlaceHolderRef.current
    ) {
      mainLayerRef.current.style.width = "100%";
      leftPlaceHolderRef.current.style.width = "0px";
      rightPlaceHolderRef.current.style.width = "0px";
      setDetailsContent(getContent());
    }
  }, [
    mainLayerRef.current,
    leftPlaceHolderRef.current,
    rightPlaceHolderRef.current,
  ]);

  useEffect(() => {
    /* 
        checking whether the clicked pixel is valid or not
        valid means that pixel should be in the selected area not to be in the leftPlaceholder or rightPlaceholder
    */

    if (
      !clickedPixelOnTheRuler ||
      clickedPixelOnTheRuler < leftPlaceHolderRef.current.offsetWidth ||
      clickedPixelOnTheRuler >=
        mainLayerRef.current.offsetWidth +
          leftPlaceHolderRef.current.offsetWidth
    )
      return;
      
    const timeAtClickedPixel = calculateSecondsForPixel(clickedPixelOnTheRuler);
    setCurrentTime(convertSecondsToHMS(timeAtClickedPixel));
    setCurrentTimeUpdated(true);
    setClickedPixelOnTheRuler();
  }, [clickedPixelOnTheRuler]);

  return (
    // <DraggableCore
    //   onDrag={handleLayerDragging}
    //   onStop={handleDragStop}
    //   axis="x"
    // >
    <SelectionContainer ref={mainLayerRef}>
      <DraggableCore
        onStart={handleLeftDragStart}
        onStop={() => {
          // setDraggerOn(false);
          handleDragStop();
        }}
        onDrag={handleLeftDragging}
        axis="x"
      >
        <DraggingPointerContainer ref={leftDraggerRef} left="true">
          <img draggable="false" src={leftArrow} alt="left" />
        </DraggingPointerContainer>
      </DraggableCore>
      <Details>{detailsContent ? detailsContent : null}</Details>
      <DraggableCore
        onStart={handleRightDragStart}
        onStop={() => {
          // setDraggerOn(false);
          handleDragStop();
        }}
        onDrag={handleRightDragging}
        axis="x"
      >
        <DraggingPointerContainer ref={rightDraggerRef} right="true">
          <img draggable="false" src={rightArrow} alt="right" />
        </DraggingPointerContainer>
      </DraggableCore>
    </SelectionContainer>
    // </DraggableCore>
  );
}

export default Selection;
