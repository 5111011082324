import {
  ControlsContainer,
  PlayAndDuration,
  PlayPauseButton,
  CurrentTime,
  Input,
  Duration,
  StopContainer,
  Stop,
} from "./Controls.styled";
import Play from "../../../../../assets/images/Play.png";
import Pause from "../../../../../assets/images/Pause.png";
import { useEffect, useState } from "react";
import useEditStore from "../../../../../store";
function Controls() {
  const {
    isVideoPlaying,
    setVideoPlaying,
    videoDuration,
    currentTime,
    setCurrentTime,
    setCurrentTimeUpdated,
    trimStartTime
  } = useEditStore();
  const handleHoursChange = (e) => {
    setCurrentTime({ ...currentTime, h: e.target.value });
    setCurrentTimeUpdated(true);
  };
  const handleMinutesChange = (e) => {
    setCurrentTime({ ...currentTime, m: e.target.value });
    setCurrentTimeUpdated(true);
  };
  const handleSecondsChange = (e) => {
    setCurrentTime({ ...currentTime, s: e.target.value });
    setCurrentTimeUpdated(true);
  };
  const handlePlayPause = () => {

    setVideoPlaying(!isVideoPlaying);
  };
  const handlePlayFromStart = () => {
    setCurrentTime(trimStartTime);
    setCurrentTimeUpdated(true);
  };

  useEffect(() => {
    window.addEventListener('keydown', function (event) {

      if (event.key === ' ' || event.keyCode === 32 || event.which === 32) {

        event.preventDefault();

        handlePlayPause();

      }
    });
    return (() => { window.removeEventListener('keydown', handlePlayPause); })

  }, [isVideoPlaying])

  if (!videoDuration) return;

  return (
    <ControlsContainer>
      <PlayAndDuration>
        <PlayPauseButton
          onClick={handlePlayPause}
          src={isVideoPlaying ? Pause : Play}
        />
        <CurrentTime>
          <Input
            inputMode="numeric"
            id="ct-hh"
            type="number"
            value={String(Math.floor(parseInt(currentTime.h))).padStart(2, "0")}
            onChange={handleHoursChange}
          />{" "}
          :
          <Input
            inputMode="numeric"
            id="ct-mm"
            type="number"
            value={String(Math.floor(parseInt(currentTime.m))).padStart(2, "0")}
            onChange={handleMinutesChange}
          />{" "}
          :
          <Input
            inputMode="numeric"
            id="ct-ss"
            type="number"
            value={String(Math.floor(parseInt(currentTime.s))).padStart(2, "0")}
            onChange={handleSecondsChange}
          />
        </CurrentTime>
        <Duration>{`${String(Math.floor(parseInt(videoDuration.h))).padStart(
          2,
          "0"
        )}:${String(Math.floor(parseInt(videoDuration.m))).padStart(
          2,
          "0"
        )}:${String(Math.floor(parseInt(videoDuration.s))).padStart(
          2,
          "0"
        )}`}</Duration>
      </PlayAndDuration>
      <StopContainer onClick={handlePlayFromStart}>
        <Stop />
      </StopContainer>
    </ControlsContainer>
  );
}

export default Controls;
