import { styled } from "styled-components";

export const RulerContainer = styled.div`
  position: absolute;
  width: calc(59px * ${(props) => props.unitsCount});
  top: 12px;
  height: 24px;
  display: flex;
  align-items: center;
  gap: 23px;
  cursor: pointer;
`;

export const Unit = styled.div`
  width: 36px;
  display: flex;
  flex-direction: column;
  gap: 9px;
  align-items: center;
`;

export const TimeLabel = styled.div`
  color: #858c94;
  text-align: center;
  font-family: Inter500;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
`;

export const Pointer = styled.div`
  width: 3px;
  height: 3px;
  background-color: #62626240;
  border-radius: 50%;
`;
