import styled from "styled-components";
// import theme from "../../theme";

export const PageNotFoundWrapper = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const PageNotFoundNav = styled.div`
  width: 100%;
  min-height: 56px;
  background-color: #404b59;
  display: flex;
  align-items: center;
`;
export const PageNotFoundNavLogoImg = styled.img`
  margin-left: 26px;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PageNotFoundLogo = styled.img``;
export const PNFErrorMsg = styled.div`
  width: 423px;
  color: #667085;
  text-align: center;
  font-family: Inter600;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
  letter-spacing: 0.3px;
  margin-top: 49px;
`;

export const PNFButton = styled.div`
  display: flex;
  width: 186px;
  height: 40px;
  padding: 12px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  border-radius: 8px;
  border: 1px solid #ccb7fc;

  overflow: hidden;
  color: #4e4e4e;
  text-overflow: ellipsis;
  whitespace: nowrap;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px; /* 140% */
  margin-top: 52px;
  cursor: pointer;
`;
