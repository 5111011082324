import { styled } from "styled-components";

export const PreviewContainer = styled.div`
    height: 61.8257%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Video = styled.video`
    width: 51.9157%;
    height: 75.3914%;
`;