import { styled } from "styled-components";

export const DialogContainer = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 45px);
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 5;
  top: 45px;
`;

export const DialogContent = styled.div`
  width: 34.72222%;
  height: 36.23789%;
  border-radius: 10px;
  background: #fff;
  min-width: 500px;
  min-height: 262px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CloseButton = styled.img`
  position: absolute;
  right: 4.8%;
  top: 10.3053%;
  cursor: pointer;
  display: ${(props) => props.hide ? "none" : "block"};
`;


//save video

export const SaveContent = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: #fff;
  min-width: 500px;
  min-height: 262px;
`;

export const Title = styled.div`
  position: absolute;
  left: 4.8%;
  top: 9.1603%;
  color: #404b59;
  font-family: Inter600;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
`;

export const NameLabel = styled.div`
  position: absolute;
  left: 4.8%;
  top: 27.48%;
  color: #667085;
  font-family: Inter400;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const NameContainer = styled.div`
  position: absolute;
  left: 4.8%;
  top: 38.461%;
  width: 90.4%;
  height: 18.32%;
  display: flex;
  align-items: center;
  justify-content: start;
  border-radius: 8px;
  border: 1px solid #ccb7fc;
`;

export const Name = styled.input`
  margin-left: 16px;
  overflow: hidden;
  color: #4e4e4e;
  text-overflow: ellipsis;
  font-family: Inter400;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: none;
  outline: none;
  &:focus{
    border: none;
    outline: none;
  }
    width: calc(100% - 32px);
    height: calc(100% - 4px);
    margin-right: 16px;
`;

export const LocationLabel = styled.div`
  position: absolute;
  left: 4.8%;
  bottom: 20.61%;
  color: #667085;
  font-family: Inter400;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const LocationContainer = styled.div`
  position: absolute;
  left:${(props) => props.processing ? "19.6%" : "4.8%"};
  bottom: ${(props) => props.processing ? "11.068%" : "11.45%"};
  display: ${(props) => props.show ? "flex" : "none"};
  gap: 8px;
`;

export const LocationPath = styled.div`
  color: ${(props) => (props.main ? "#404B59" : "#667085")};
  font-family: ${(props) => (props.dashboardLabel ? "Inter400" : "Inter500")} ;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
`;

export const SaveButton = styled.div`
  display: flex;
  width: 18.6%;
  height: 12.21%;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #6528f7;
  position: absolute;
  right: 4.8%;
  bottom: 9.92366%;
  cursor: pointer;
`;

export const ButtonText = styled.div`
  color: #fff;
  font-family: Inter600;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.07px;

`;

//Processing

export const ProcessingContent = styled.div`
  width: calc(100% - (2 * 4.8%));
  height: 100%;
  border-radius: 10px;
  background: #fff;
`;

export const ProcessingTitle = styled.div`
  color: #404b59;
  font-family: Inter600;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  top: 17.55725%;
  position: absolute;
  left: 30.6%
`;


export const ProgressBar = styled.div`
  position: absolute;
  top: 36.641%;
  width: calc(100% - (2 * 4.8%));
  height: 6px;
  border-radius: 7px;
  background: #DCE6FF;
`;

export const Progress = styled.div`
  height: 100%;
  width: 5%;
  border-radius: 8px;
  background: #6528F7;
`;

export const Description = styled.div`
position: absolute;
  top: 46.946%;
  left: 19.2%;
  color: #667085;
font-family: Inter400;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px; 
`;

export const DashboardButton = styled.div`
  border-radius: 4px;
  background: #6528F7;
  height: 12.213%;
  width: 24.8%;
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 29.007%;
  left: 37.6%;
`



