import Header from "../Header/Header.index";
import Options from "../Options/Options.index";
import Properties from "../Properties/Properties.index";
import EditingPanel from "../EditingPanel/EditingPanel.index";
import { WrapperContainer, Panels, ComingSoon } from "./Wrapper.styled";
import { useEffect } from "react";
import { deleteVideoFromServer } from "../../helperFunctions/backendCalls";
import useEditStore from "../../store";
import { useParams } from "react-router-dom";
import { getVideoDetails } from "../../helperFunctions/backendCalls";
import Dialog from "../Dialog/Dialog.index";
import { cancelTrimRequest } from "../../helperFunctions/axios-utils";
import { useNavigate } from "react-router-dom";

function Wrapper() {
  const navigate = useNavigate();

  const {
    userDetails,
    setUserDetails,
    videoDetails,
    setVideoDetails,
    isPageNotFound,
    setPageNotFound,
    isPublishClicked,
  } = useEditStore();
  let { videoid } = useParams();

  useEffect(() => {
    setUserDetails({ fileName: videoid });
    setVideoDetails({ id: videoid });
  }, [videoid]);

  const manipulateLinkUrl = (url, email) => {
    const splitUrl = url.split("/");
    const userName =
      url.includes === "trim/Flonnect"
        ? `${splitUrl[splitUrl.length - 3]}/trim`
        : splitUrl[splitUrl.length - 2];
    setUserDetails({ userName, email });
  };
  useEffect(() => {
    if (videoDetails && videoDetails.id && !videoDetails.source) {
      getVideoDetails(videoDetails.id)
        .then((data) => {
          manipulateLinkUrl(data.video.linkUrl, data.email);

          setVideoDetails({
            id: data.video.id,
            source: data.video.linkUrl,
            thumbnail: data.video.thumbnailUrl,
          });
        })
        .catch(() => {
          navigate("/access-denied");
        });
    }
  }, [videoDetails]);

  const restrictRefrsh = (e) => {
    // e.preventDefault(); // Prevent the browser's default dialog

    // Display a confirmation message
    const confirmationMessage = "If you refresh, no data will be saved.";
    e.returnValue = confirmationMessage; // For older browsers

    // Show the confirmation box
    const userConfirmed = window.confirm(confirmationMessage);

    // If the user confirms, proceed with the refresh
    if (userConfirmed) {
      //aborting trim client
      cancelTrimRequest();
      deleteVideoFromServer({
        inputPath: `${userDetails.userName}/`,
        videoId: videoDetails.id,
      })
        .then(() => console.log("unmount-success"))
        .catch(() => console.log("unmount-err"))
        .finally(() => window.location.reload());
    }
  };

  const handleUnload = (e) => {
    deleteVideoFromServer({
      inputPath: `${userDetails.userName}/`,
      videoId: videoDetails.id,
    })
      .then(() => console.log("unmount-success"))
      .catch(() => console.log("unmount-err"))
      .finally(() => {
        //trigger unload or close
      });
  };

  useEffect(() => {
    window.addEventListener("beforeunload", restrictRefrsh);
    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", restrictRefrsh);
      window.addEventListener("unload", handleUnload);
    };
  }, []);
  // if (isPageNotFound)

  return (
    <WrapperContainer>
      <Header />
      <Panels>
        <Options />
        <Properties />
        <EditingPanel />
      </Panels>
      <Dialog enable={isPublishClicked ? true : false} />
      <ComingSoon enable={isPageNotFound ? false : true}>
        Coming soon
      </ComingSoon>
    </WrapperContainer>
  );
}

export default Wrapper;
