import { styled } from "styled-components";

export const TrimLayerContainer = styled.div`
  height: 52px;
  border-radius: 8px;
  border: 1px dashed rgba(0, 0, 0, 0.2);
  width: 100%;
  position: absolute;
  top: 27.72727%;
  left: 16px;
  width: calc(100% - 16px);
`;

export const Container = styled.div`
    position: relative;
    width: ${(props) => props.width ? String(props.width) + "px" : "100%"};
    height: 100%;
    display: flex;
`;

export const LeftPlaceHolder = styled.div`
    width: 0px;
`;
export const SelectionContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 2px solid #f84cc4;
  background: #fcfcfd;
  position: relative;
  height: 100%;
  bottom: 2px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: start;
`;
export const RightPlaceHolder = styled.div`
  width: 0px;
`;

export const DraggingPointerContainer = styled.div`
  display: flex;
  width: 12px;
  height: 36px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 18px;
  background: #f84cc4;
  position: absolute;
  top: 8px;
  left: ${(props) => (props.left ? "-7px" : "unset")};
  right: ${(props) => (props.right ? "-7px" : "unset")};
  cursor: ew-resize;
`;

export const Details = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 99px;
  width: 100%;
  overflow: hidden;
`;

export const LayerDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

`;

export const Image = styled.img`

`;

export const Name = styled.div`
  height: 28px;
  color: #1e2126;
  text-align: center;
  font-family: Inter400;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 3px;
  border: 0.5px solid #dce1e9;
  text-align: center;
  width: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
`;