export function convertSecondsToHMS(secondsWithMicroseconds) {
  const totalSeconds = Math.floor(secondsWithMicroseconds);
  const microseconds = (secondsWithMicroseconds - totalSeconds) * 1000000;

  const hours = Math.floor(totalSeconds / 3600);
  const remainingSecondsAfterHours = totalSeconds % 3600;
  const minutes = Math.floor(remainingSecondsAfterHours / 60);
  const remainingSeconds = Math.floor(remainingSecondsAfterHours % 60);

  const result = {
    h: hours,
    m: minutes,
    s: remainingSeconds,
    us: microseconds,
  };

  return result;
}

export function convertTimeObjectToSeconds(timeObject) {
  const { h = 0, m = 0, s = 0, us = 0 } = timeObject;
  const hoursInSeconds = parseInt(h, 10) * 3600;
  const minutesInSeconds = parseInt(m, 10) * 60;
  const seconds = parseInt(s, 10);
  const microseconds = parseInt(us, 10) / 1000000;
  return hoursInSeconds + minutesInSeconds + seconds + microseconds;
}
