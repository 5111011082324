import { styled, keyframes } from "styled-components";

const moveIndicator = (from, to) => keyframes`
  0%{
    transform: translate3d(${from}px, 0, 0);
  }

  100%{
    transform: translate3d(${to}px, 0, 0);
  }
`;

export const TimeIndicator = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: -60%;

  z-index: 1;
  transform: translate(-10px, 0);
  transition: transform 0.1s linear;
  cursor: grabbing;
  #indicator-hand {
    height: 14px;
  }
  #indicator-line {
    height: 245px;
  }
`;
