import {
  OptionsContainer,
  ActionsContainer,
  ActionsImage,
  ActionsText,
} from "./Options.styled";
import option2 from "../../assets/images/options/option2.png";
import option1 from "../../assets/images/options/option1.png";
import options from "../../constants/options";
import { useEffect, useState } from "react";

function Options() {
  const [allOptions, setAllOption] = useState()
  const generateOptions = async () => {
    const optionsElements = await Promise.all(
      Object.keys(options).map(async (option) => {
        const {isActive, name} = options[`${option}`];
        const imageSrc = (await options[`${option}`].image).default;
        return (
          <ActionsContainer key={option} active={isActive}>
            <ActionsImage active={isActive}>
              <img src={imageSrc} alt={option} />
            </ActionsImage>
            <ActionsText>{name}</ActionsText>
          </ActionsContainer>
        );
      })
    )
    return optionsElements
  }
  useEffect(() => {
    generateOptions().then((data) => setAllOption(data))
  }, [])
  return (
    <OptionsContainer>
      {allOptions ? allOptions : null}
    </OptionsContainer>
  );
}

export default Options;
