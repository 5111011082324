import { styled } from "styled-components";

export const WrapperContainer = styled.div`
    height: 100vh;
    width: 100vw;
`;

export const Panels = styled.div`
    display: flex;
    height: calc(100vh - 45px);
`;

export const ComingSoon = styled.div`
  display: ${(props) => props.enable ? "flex" : "none"};
  width: 85px;
  height: 24px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 30px;
  background: #7f4cf8;
  position: absolute;
  left: 25px;
  top: 58px;
  color: #fff;
  font-family: Inter400;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;