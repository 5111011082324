import { useEffect, useRef, useState } from "react";

import { PreviewContainer, Video } from "./Preview.styled";
import useEditStore from "../../../store";
import {
  convertSecondsToHMS,
  convertTimeObjectToSeconds,
} from "../../../helperFunctions/timeConversion";

function Preview() {
  const videoRef = useRef(null);
  const {
    isVideoPlaying,
    setVideoPlaying,
    setVideoDuration,
    currentTime,
    setCurrentTime,
    isCurrentTimeUpdated,
    setCurrentTimeUpdated,
    trimStartTime,
    videoDetails, trimPortion, setTrimEndTime, setTrimPortion
  } = useEditStore();
  const playVideo = async () => {
    try {
      await videoRef.current.play();
    } catch (err) {
      console.log();
    }
  };
  const pauseVideo = async () => {
    try {
      await videoRef.current.pause();
    } catch (err) {
      console.log();
    }
  };

  const handleTimeUpdate = () => {
    const videoElement = videoRef.current;
    setCurrentTime(convertSecondsToHMS(videoElement.currentTime));


    if ((videoElement?.currentTime >= trimPortion.end)) {
      setVideoPlaying(false)
      videoElement.pause()
      videoElement.currentTime = trimPortion.start
      setCurrentTime(convertSecondsToHMS(trimPortion.start));
    };
  };

  const handleLoadedMetadata = () => {
    const videoElement = videoRef.current;
    const durationInSecs = videoElement.duration
    const durationInHMS = convertSecondsToHMS(videoElement.duration)
    setTrimPortion({ end: durationInSecs })
    setVideoDuration(durationInHMS);
    setTrimEndTime(durationInHMS)
  };
  const handleEndVideo = () => {
    setVideoPlaying(false);
  };
  useEffect(() => {
    if (videoDetails && videoRef.current) {
      if(isCurrentTimeUpdated){
        videoRef.current.currentTime = convertTimeObjectToSeconds(currentTime);
        setCurrentTimeUpdated(false);
      }
      const videoElement = videoRef.current;
      videoElement.addEventListener("timeupdate", handleTimeUpdate);
      videoElement.addEventListener("loadedmetadata", handleLoadedMetadata);
      videoElement.addEventListener("ended", handleEndVideo);
      return () => {
        videoElement.removeEventListener("timeupdate", handleTimeUpdate);
        videoElement.removeEventListener(
          "loadedmetadata",
          handleLoadedMetadata
        );
      };
    }
  }, [videoDetails, videoRef.current, trimPortion, isCurrentTimeUpdated]);
  useEffect(() => {
    if (isVideoPlaying) playVideo();
    else pauseVideo();
  }, [isVideoPlaying]);


  useEffect(() => {
    if (trimStartTime && videoRef && videoRef.current) {
      videoRef.current.currentTime = convertTimeObjectToSeconds(trimStartTime)
    }
  }, [trimStartTime])


  return (
    <PreviewContainer>
      {!videoDetails || !videoDetails.source ? null : <Video
        ref={videoRef}
        src={videoDetails.source}
        type="video/mp4"
      ></Video>}

    </PreviewContainer>
  );
}

export default Preview;
