import { styled } from "styled-components";

export const PropertiesContainer = styled.div`
  width: 320px;
  position: relative;
`;

export const Header = styled.div`
  color: #1e2126;
  font-family: Inter400;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-top: 27px;
`;

export const Content = styled.div`
  position: absolute;
  left: 24px;
  top: 82px;
`;

export const SubHeader = styled.div`
  color: #667085;
  font-family: Inter400;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 12px;
`;

export const LayerNameContainer = styled.div`
  display: flex;
  align-items: center;
  width: 272px;
  height: 48px;
  border-radius: 8px;
  border: 1px solid #dce1e9;
  background: #fff;
  margin-bottom: 24px;
  position: relative;
`;

export const LogoContainer = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 2.5px;
  background: #f84cc4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  img {
    width: 5px;
    height: 5px;
  }
`;

export const Name = styled.div`
  overflow: hidden;
  color: #4e4e4e;
  text-overflow: ellipsis;
  font-family: Inter400;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-left: 8px;
`;

export const DropDownIcon = styled.img`
  position: absolute;
  right: 12px;
`;

export const AddIcon = styled.img`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 106px;
  right: 0;
`;

export const Timeline = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
`;

export const Time = styled.div`
  border-radius: 8px;
  border: 1px solid #dce1e9;
  background: #fff;
  width: 122px;
  height: 48px;
  border-radius: 8px;
  border: 1px solid #dce1e9;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

export const Label = styled.span`
  color: #1e212650;
  font-family: Inter400;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const DurationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1e2126;
  font-family: Inter400;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const DurationInput = styled.input`
  outline: none;
  border: none;
  color: #1e2126;
  font-family: Inter400;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 20px;
  padding: 0;
  &:focus {
    outline: none;
    border: none;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const MoreDetailsIcon = styled.img`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 158px;
  right: 0;
`;
