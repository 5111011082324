import Draggable from "react-draggable";

import Controls from "./components/Controls/Controls.index";

import {
  LayersContainer,
  FramesContainer,
  FramesImage,
  MainContainer,
  FilmStripContainer,
  FilmStrip,
  LeftHiddenFrames,
  RightHiddenFrames,
} from "./Layers.styled";

import thumbnail from "../../../assets/images/frames/temp001.jpg";

import { useEffect, useRef, useState } from "react";

import Indicator from "./components/Indicator/Indicator.index";

import Ruler from "./components/Ruler/Ruler.index";

import useEditStore from "../../../store";

import { convertTimeObjectToSeconds } from "../../../helperFunctions/timeConversion";

import Zoom from "./components/Zoom/Zoom.index";

import Tools from "./components/Tools/Tool.index";

import TrimLayer from "./components/TrimLayer/TrimLayer.index";

import { extractFrames } from "../../../helperFunctions/backendCalls";

import { cancelRequest } from "../../../helperFunctions/axios-utils";

import _static from "../../../config/static";

function Layers() {
  const { baseUrl } = _static;

  const {
    videoDuration,

    filmStripWidthPercent,

    setFramesContainerRef,

    setFramesExtracting,

    isFramesExtracting,
    userDetails,
    setUserDetails,
    videoDetails,
    setLeftHiddenFramesRef,
    setRightHiddenFramesRef,
  } = useEditStore();

  const framesContainerRef = useRef(null);

  const mainContainerRef = useRef(null);

  const leftHiddenFramesRef = useRef(null);
  const rightHiddenFramesRef = useRef(null);

  const [allFrames, setFrames] = useState();

  const generateFrames = async () => {
    if (!userDetails || !userDetails.fileName || !userDetails.userName) return;

    const frameElements = [];

    for (let i = 1; i <= 20; i += 1) {
      const imageSrc = `${baseUrl}/frames/${userDetails.fileName}/frame0${
        i < 10 ? `0${i}` : i
      }.jpg`;

      frameElements.push(<FramesImage key={`frame${i}`} image={imageSrc} />);
    }

    return frameElements;
  };

  useEffect(() => {
    if (!userDetails || !userDetails.fileName || !userDetails.userName) return;

    const reqData = {
      inputPath: `${userDetails.userName}/`,

      filename: userDetails.fileName,
    };

    extractFrames(reqData).then((data) => {
      generateFrames().then((data) => setFrames(data));
    });

    return () => {
      cancelRequest();
    };
  }, [userDetails]);

  useEffect(() => {
    setFramesContainerRef(framesContainerRef);
  }, [
    framesContainerRef.current?.offsetWidth,

    mainContainerRef.current,

    filmStripWidthPercent,
  ]);

  useEffect(() => {
    if (leftHiddenFramesRef.current)
      setLeftHiddenFramesRef(leftHiddenFramesRef);
    if (rightHiddenFramesRef.current)
      setRightHiddenFramesRef(rightHiddenFramesRef);
  }, []);

  if (!userDetails) return;

  return (
    <LayersContainer>
      {/* <Tools /> */}

      <Controls />

      <MainContainer ref={mainContainerRef}>
        <TrimLayer mainContainerRef={mainContainerRef} />

        <FilmStripContainer>
          <FilmStrip id="film-strip">
            <FramesContainer
              duration={convertTimeObjectToSeconds(videoDuration)}
              zoomPercent={filmStripWidthPercent}
              ref={framesContainerRef}
              thumbnail={
                videoDetails && videoDetails.thumbnail
                  ? videoDetails.thumbnail
                  : null
              }
            >
              {allFrames ? allFrames : null}
              <LeftHiddenFrames
                ref={leftHiddenFramesRef} /* width={leftHiddenFramesWidth} */
              />
              <RightHiddenFrames
                ref={rightHiddenFramesRef}
                // width={rightHiddenFramesWidth}
              />
            </FramesContainer>

            <Indicator
              framesContainer={framesContainerRef}
              mainContainerRef={mainContainerRef}
            />
          </FilmStrip>
        </FilmStripContainer>

        <Ruler mainContainerRef={mainContainerRef} />
      </MainContainer>

      <Zoom mainContainerRef={mainContainerRef} />
    </LayersContainer>
  );
}

export default Layers;
