const options = {
  option1: {
    isActive: true,
    name: "Actions",
    image: import("../assets/images/options/option1.png"),
  },
  option2: {
    isActive: false,
    name: "Layers",
    image: import("../assets/images/options/option2.png"),
  },
  option3: {
    isActive: false,
    name: "Media",
    image: import("../assets/images/options/option3.png"),
  },
  option4: {
    isActive: false,
    name: "Text",
    image: import("../assets/images/options/option4.png"),
  },
  option5: {
    isActive: false,
    name: "Shapes",
    image: import("../assets/images/options/option5.png"),
  },
  option6: {
    isActive: false,
    name: "Blur",
    image: import("../assets/images/options/option6.png"),
  },
  option7: {
    isActive: false,
    name: "Links",
    image: import("../assets/images/options/option7.png"),
  },
};

export default options