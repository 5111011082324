import { styled } from "styled-components";
export const ControlsContainer = styled.div`
  display: flex;
  width: 252px;
  justify-content: center;
  align-items: flex-start;
  gap: 7px;

  position: absolute;
  top: 10px;
  left: 41.1877%;
`;

export const PlayAndDuration = styled.div`
  border-radius: 33px;
  border: 1px solid #e0d4fd;
  background: #fcfcfd;
  width: 211px;
  height: 36px;
  flex-shrink: 0;
  position: relative;
`;

export const PlayPauseButton = styled.img`
  height: 32px;
  width: 32px;
  position: absolute;
  left: 16px;
  top: 2px;
  cursor: pointer;
`;
export const CurrentTime = styled.div`
  width: 68px;
  height: 19px;
  position: absolute;
  top: 9px;
  left: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1e2126;
  font-family: Inter400;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.96px;
`;
export const Input = styled.input`
  outline: none;
  border: none;
  color: #1e2126;
  font-family: Inter400;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.96px;
  width: 20px;
  &:focus {
    outline: none;
    border: none;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const Duration = styled.div`
  color: #404b59;
  text-align: center;
  font-family: Inter300;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.72px;
  width: 62px;
  position: absolute;
  right: 15px;
  top: 11px;
`;

export const StopContainer = styled.div`
  display: flex;
  width: 34px;
  height: 34px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 7px;
  border: 1px solid #e0d4fd;
  cursor: pointer;
`;

export const Stop = styled.div`
  border-radius: 2px;
  background: #667085;
  width: 10px;
  height: 10px;
  flex-shrink: 0;
`;
