import styled from "styled-components"

export const MainHeader = styled.div`
  width: 100%;
  height: 45px;
  background-color: #7f4cf8;
  position: relative;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  position: absolute;
  top: 17px;
  left: 25px;
`;

export const Text = styled.div`
  color: #fff;
  font-family: Inter400;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Logo = styled.img`
  width: 16px;
  height: 16px;
`;

export const PublishButton = styled.div`
  width: 124px;
  height: 29px;
  position: absolute;
  right: 12px;
  top: 8px;
  border-radius: 4px;
  background: #fff;
  display: ${(props) => props.enable ? "flex" : "none"};
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
export const ButtonText = styled.div`
  color: #1e2126;
  font-family: Inter400;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  
`;