import { styled } from "styled-components";

export const LayersContainer = styled.div`
  height: 38.17427%;
  background: #fff;
  position: relative;
`;

export const MainContainer = styled.div`
  left: 34px;
  width: calc(100% - 34px);
  border-left: 0.5px solid #d9d9d9;
  border-top: 0.5px solid #d9d9d9;
  position: absolute;
  top: 20.2898%;
  height: 79.7101%;
  overflow-x: auto;

  &::-webkit-scrollbar-track {
    border-radius: 3px;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #737373;
    cursor: pointer;
  }
`;

export const FilmStripContainer = styled.div`
  position: absolute;
  bottom: 13.405%;
  left: 16px;
  width: calc(100% - 16px);
`;

export const FilmStrip = styled.div`
  position: relative;
`;

export const FramesContainer = styled.div`
  border-radius: 8px;
  border: 2px solid #f84cc4;
  background-size: auto 100%;
  background-image: url("${(props) => props.thumbnail}");
  background-repeat: repeat-x;
  width: calc(
    59px * ${(props) => props.duration} * ${(props) => props.zoomPercent} - 4px - 8.4px - 8.4px
  );
  height: 52px;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const FramesImage = styled.div`
  height: 52px;
  width: 5%;
  background-image: url(${(props) => props.image});
  background-repeat: repeat-x;
`;

export const LeftHiddenFrames = styled.div`
  position: absolute;
  left: 0;
  height: 100%;
  width: ${(props) => (props.width ? props.width : 0)}px;
  top: 0;
  opacity: 0.5;
  background: black;
  border-radius: 6px 0 0 6px;
`;

export const RightHiddenFrames = styled.div`
  position: absolute;
  right: 0px;
  height: 100%;
  width: ${(props) => (props.width ? props.width : 0)}px;
  top: 0;
  opacity: 0.5;
  background: black;
  border-radius: 0 6px 6px 0;
`;