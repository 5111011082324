import styled from "styled-components";

export const Removed = styled.div`
  background-color: #dcdcdc;
  position: fixed;
  z-index: 102;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const RemovedNav = styled.div`
  position: fixed;
  top: 0;
  background-color: #2c2c2c;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  .grey {
    margin-left: 44px;
  }
`;

export const ContentDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  margin-top: 15.3%;
`;

export const InfoContainer = styled.div`
  width: 400px;
  height: 233px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #fff;
  .requestWrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #acc-img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0 16px 0;
  }
  .info {
    color: #404b59;
    text-align: center;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: 0.3px;
  }
  .requestInfo {
    width: 287px;
  }
  .description {
    display: flex;
    justify-content: center;
    margin: 16px 0 36px 0;
    color: #667085;
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 150% */
    letter-spacing: 0.5px;
  }
  .requestBtn {
    background: none;
    overflow: hidden;
    display: -webkit-box;
    width: 124px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    color: #4e4e4e;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.4px; /* 140% */
    display: flex;
    width: 175px;
    height: 40px;
    padding: 12px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #ccb7fc;
  }
`;
