import { styled } from "styled-components";

export const OptionsContainer = styled.div`
    width: 76px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 4px;
    margin-top: 40px;
    gap: 4px;
    opacity: 0.6;
`;

export const ActionsContainer = styled.div`
  width: 68px;
  height: 73px;
  border-radius: 12px;
  background: ${(props) => props.active ? "#f7f4ff" : "rgba(247, 244, 255, 0.00)"};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const ActionsImage = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: ${(props) => (props.active ? "#b293fb" : "#fff")};
  box-shadow: ${(props) =>
    props.active ? "0px 2px 5px 0px rgba(38, 51, 77, 0.03)" : "none"};
  border: ${(props) => !props.active ? "1px solid #DCE1E9" : "none"};
`;

export const ActionsText = styled.div`
  color: #404b59;
  text-align: center;
  font-family: Inter500;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

