import axios from "axios";
import _static from "../config/static";
const { baseUrl, ec2BaseUrl } = _static;

const errorHandler = (err) => {
    if (err?.response?.status === 401) {
      return Promise.reject(err);
    }

  if (err.code === "ERR_CANCELED") {
    return Promise.reject(err)
  }

  if (err.name === "AbortError") {
    return Promise.reject(err)
  }
  if(err?.config?.url?.includes("getEditVideoDetails")){
    return Promise.reject(err);
  }
  if(err?.config?.url?.includes("revamp/trim")){
    return Promise.reject(err);
  }
  return Promise.reject(err);
};

export const appClient = axios.create({
  baseURL: baseUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  }

});

// Create an instance of AbortController
const abortController = new AbortController();

// Create an interceptor for request cancellation
appClient.interceptors.request.use((config) => {
  // Add the abort controller's signal to the request
  config.signal = abortController.signal;
  return config;
});

// Create an interceptor for handling response errors
appClient.interceptors.response.use((response) => {
  return response.data;
}, errorHandler);

export const cancelRequest = () => {
  // Call this function to cancel the request
  abortController.abort();
};


//ec2 client for getvideodetails

export const ec2Client = axios.create({
  baseURL: ec2BaseUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    
  },
  withCredentials:true
});

ec2Client.interceptors.response.use((response) => {

  return response.data;
}, errorHandler);


//trimming client

export const trimClient = axios.create({
  baseURL: baseUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  }
});

// Create an instance of AbortController
const abortControllerForTrim = new AbortController();

// Create an interceptor for request cancellation
trimClient.interceptors.request.use((config) => {
  // Add the abort controller's signal to the request
  config.signal = abortControllerForTrim.signal;
  return config;
});

// Create an interceptor for handling response errors
trimClient.interceptors.response.use((response) => {
  return response.data;
}, errorHandler);

export const cancelTrimRequest = () => {
  // Call this function to cancel the request
  abortControllerForTrim.abort();
};