import {
  PropertiesContainer,
  Header,
  SubHeader,
  Content,
  LayerNameContainer,
  LogoContainer,
  Name,
  DropDownIcon,
  AddIcon,
  Timeline,
  Label,
  Duration,
  Time,
  DurationContainer,
  DurationInput,
  MoreDetailsIcon,
} from "./Properties.styled";
import layer from "../../assets/images/options/option3.png";
import dropdown from "../../assets/images/dropdown.svg";
import add from "../../assets/images/add.svg";
import kebab from "../../assets/images/kebab.svg";
import useEditStore from "../../store";
import { useEffect, useRef } from "react";
import { convertTimeObjectToSeconds } from "../../helperFunctions/timeConversion";
function Properties() {
  const {
    trimStartTime,
    setTrimStartTime,
    framesContainerRef,
    trimPortion,
    setTrimPortion,
    trimEndTime,
    setTrimEndTime,
    setTrimUpdatedByInput,
    videoDuration
  } = useEditStore();

  const trimStartHoursInputRef = useRef(null);
  const trimStartMinutesInputRef = useRef(null);
  const trimStartSecondsInputRef = useRef(null);
  const trimEndHoursInputRef = useRef(null);
  const trimEndMinutesInputRef = useRef(null);
  const trimEndSecondsInputRef = useRef(null);


  const handleStartHoursChange = (e) => {
    let value = parseInt(e.target.value) < 0 ? 0 : e.target.value
    trimStartHoursInputRef.current.value = value;
  };
  const handleTrimStartHoursBlur = (e) => {
    const value = e.target.value === "" ? 0 : e.target.value
    setTrimStartTime({ ...trimStartTime, h: parseInt(value) });
    setTrimPortion({
      start: convertTimeObjectToSeconds({
        ...trimStartTime,
        h: parseInt(value),
      }),
    });
    setTrimUpdatedByInput(true)
  }

  const handleStartMinutesChange = (e) => {
    let value = parseInt(e.target.value) < 0 ? 0 : e.target.value
    trimStartMinutesInputRef.current.value = value;
  };
  const handleTrimStartMinutesBlur = (e) => {
    const value = e.target.value === "" ? 0 : e.target.value
    setTrimStartTime({ ...trimStartTime, m: parseInt(value) });
    setTrimPortion({
      start: convertTimeObjectToSeconds({
        ...trimStartTime,
        m: parseInt(value),
      }),
    });
    setTrimUpdatedByInput(true)
  }

  const handleStartSecondsChange = (e) => {
    if(parseInt(e.target.value) > 0 && parseInt(e.target.value) < 60){
      trimStartSecondsInputRef.current.value = e.target.value;
    }
  };
  const handleTrimStartSecondsBlur = (e) => {
    const value = e.target.value === "" ? 0 : e.target.value
    const currentStartTimeInSecs = convertTimeObjectToSeconds({ ...trimStartTime, s: parseInt(value) })
    const videoDurationInSecs = convertTimeObjectToSeconds(videoDuration)
    if(currentStartTimeInSecs < trimPortion.end && currentStartTimeInSecs < videoDurationInSecs){
        setTrimStartTime({ ...trimStartTime, s: parseInt(value) });
        setTrimPortion({start: convertTimeObjectToSeconds({...trimStartTime, s: parseInt(value)})});
        setTrimUpdatedByInput(true)
        return
    }
    trimStartSecondsInputRef.current.value = String(Math.floor(parseInt(trimStartTime.s))).padStart(2, "0")
  }

  const handleEndHoursChange = (e) => {
    let value = parseInt(e.target.value) >= videoDuration.h ? videoDuration.h : e.target.value
    trimEndHoursInputRef.current.value = value;
  };

  const handleTrimEndHoursBlur = (e) => {
    const value = e.target.value === "" ? 0 : e.target.value
    setTrimEndTime({ ...trimEndTime, h: parseInt(value) });
    setTrimPortion({
      end: convertTimeObjectToSeconds({
        ...trimEndTime,
        h: parseInt(value),
      }),
    });
    setTrimUpdatedByInput(true)
  }

  const handleEndMinutesChange = (e) => {
    let value = parseInt(e.target.value) >= videoDuration.m ? videoDuration.m : e.target.value
    trimEndMinutesInputRef.current.value = value;
  };
  const handleTrimEndMinutesBlur = (e) => {
    const value = e.target.value === "" ? 0 : e.target.value
    setTrimEndTime({ ...trimEndTime, m: parseInt(value) });
    setTrimPortion({
      end: convertTimeObjectToSeconds({
        ...trimEndTime,
        m: parseInt(value),
      }),
    });
    setTrimUpdatedByInput(true)
  }
  const handleEndSecondsChange = (e) => {
    let value = parseInt(e.target.value) >= videoDuration.s ? videoDuration.s : e.target.value
    trimEndSecondsInputRef.current.value = value;
  };

  const handleTrimEndSecondsBlur = (e) => {
    const value = e.target.value === "" ? 0 : e.target.value
    setTrimEndTime({ ...trimEndTime, s: parseInt(value) });
    setTrimPortion({
      end: convertTimeObjectToSeconds({
        ...trimEndTime,
        s: parseInt(value),
      }),
    });
    setTrimUpdatedByInput(true)
  }

   const handleInputChange = (e, ref) => {
    if(parseInt(e.target.value) < 0 && parseInt(e.target.value) < 60){
      ref.current.value = 0
      return
    }
    if(parseInt(e.target.value) > 60){
      ref.current.value = 60
      return
    }
    ref.current.value = e.target.value
  }

  const handleStartInputBlur = (e, key, ref) => {
    const value = e.target.value === "" ? 0 : e.target.value
    const currentStartTimeInSecs = convertTimeObjectToSeconds({ ...trimStartTime, [key]: parseInt(value) })
    const videoDurationInSecs = convertTimeObjectToSeconds(videoDuration)
    if(currentStartTimeInSecs < trimPortion.end && currentStartTimeInSecs < videoDurationInSecs){
        setTrimStartTime({ ...trimStartTime, [key]: parseInt(value) });
        setTrimPortion({start: convertTimeObjectToSeconds({...trimStartTime, [key]: parseInt(value)})});
        setTrimUpdatedByInput(true)
        return
    }
    ref.current.value = String(Math.floor(parseInt(trimStartTime[key]))).padStart(2, "0")
  }

  const handleEndInputBlur = (e, key, ref) => {
    const value = e.target.value === "" ? 0 : e.target.value
    const currentEndTimeInSecs = convertTimeObjectToSeconds({ ...trimEndTime, [key]: parseInt(value) })
    const videoDurationInSecs = convertTimeObjectToSeconds(videoDuration)
    if(currentEndTimeInSecs > trimPortion.start && currentEndTimeInSecs <= videoDurationInSecs){
        setTrimEndTime({ ...trimEndTime, [key]: parseInt(value) });
        setTrimPortion({end: convertTimeObjectToSeconds({...trimEndTime, [key]: parseInt(value)})});
        setTrimUpdatedByInput(true)
        return
    }
    ref.current.value = String(Math.floor(parseInt(trimEndTime[key]))).padStart(2, "0")
  }


  

  useEffect(() => {
    trimEndHoursInputRef.current.value = String(Math.floor(parseInt(trimEndTime.h))).padStart(2, "0")
    trimEndMinutesInputRef.current.value = String(Math.floor(parseInt(trimEndTime.m))).padStart(2, "0")
    trimEndSecondsInputRef.current.value = String(Math.floor(parseInt(trimEndTime.s))).padStart(2, "0")
  }, [trimEndTime])

   useEffect(() => {
    trimStartHoursInputRef.current.value = String(Math.floor(parseInt(trimStartTime.h))).padStart(2, "0")
    trimStartMinutesInputRef.current.value = String(Math.floor(parseInt(trimStartTime.m))).padStart(2, "0")
    trimStartSecondsInputRef.current.value = String(Math.floor(parseInt(trimStartTime.s))).padStart(2, "0")
  }, [trimStartTime])


  return (
    <PropertiesContainer>
      <Header>Trim</Header>
      <Content>
        <SubHeader>Layer</SubHeader>
        <LayerNameContainer>
          <LogoContainer>
            <img src={layer} alt="layer" />
          </LogoContainer>
          <Name>Trim layer</Name>
          <DropDownIcon src={dropdown} alt="dropdown" />
        </LayerNameContainer>
        <SubHeader>Timeline</SubHeader>
        <AddIcon src={add} alt="add more" />
        <Timeline>
          <Time>
            <Label>S:</Label>
            <DurationContainer>
              <DurationInput
                ref = {trimStartHoursInputRef}
                onBlur = { (e) => handleStartInputBlur(e, "h", trimStartHoursInputRef)}
                onChange={(e) => handleInputChange(e, trimStartHoursInputRef)}
                inputMode="numeric"
                id="tt-s-hh"
                type="number"
                placeholder="hh"
              />
              :
              <DurationInput
                ref = {trimStartMinutesInputRef}
                onBlur = { (e) => handleStartInputBlur(e, "m", trimStartMinutesInputRef)}
                onChange={(e) => handleInputChange(e, trimStartMinutesInputRef)}
                inputMode="numeric"
                id="tt-s-mm"
                type="number"
                placeholder="mm"
              />
              :
              <DurationInput
                ref = {trimStartSecondsInputRef}
                onBlur = { (e) => handleStartInputBlur(e, "s", trimStartSecondsInputRef)}
                onChange={(e) => handleInputChange(e, trimStartSecondsInputRef)}
                inputMode="numeric"
                id="tt-s-ss"
                type="number"
                placeholder="ss"
              />
            </DurationContainer>
          </Time>
          <Time>
            <Label>E:</Label>
            <DurationContainer>
              <DurationInput
                ref = {trimEndHoursInputRef}
                onBlur = { (e) => handleEndInputBlur(e, "h", trimEndHoursInputRef)}
                onChange={(e) => handleInputChange(e, trimEndHoursInputRef)}
                inputMode="numeric"
                id="tt-e-hh"
                type="number"
                placeholder="hh"
              />
              :
              <DurationInput
                ref = {trimEndMinutesInputRef}
                onBlur = { (e) => handleEndInputBlur(e, "m", trimEndMinutesInputRef)}
                onChange={(e) => handleInputChange(e, trimEndMinutesInputRef)}
                inputMode="numeric"
                id="tt-e-mm"
                type="number"
                placeholder="mm"
              />
              :
              <DurationInput
                ref = {trimEndSecondsInputRef}
                onBlur = { (e) => handleEndInputBlur(e, "s", trimEndSecondsInputRef)}
                onChange={(e) => handleInputChange(e, trimEndSecondsInputRef)}
                inputMode="numeric"
                id="tt-e-ss"
                type="number"
                placeholder="ss"
              />
            </DurationContainer>
          </Time>
        </Timeline>
        <MoreDetailsIcon src={kebab} alt="more details" />
      </Content>
    </PropertiesContainer>
  );
}

export default Properties;
