import { useEffect, useRef } from "react";
import {
  ButtonText,
  DashboardButton,
  Description,
  LocationContainer,
  LocationPath,
  ProcessingContent,
  ProcessingTitle,
  Progress,
  ProgressBar,
} from "./Dialog.styled";
import { getVideoDetails, trim } from "../../helperFunctions/backendCalls";
import useEditStore from "../../store";
import { convertTimeObjectToSeconds } from "../../helperFunctions/timeConversion";
import { deleteVideoFromServer } from "../../helperFunctions/backendCalls";
function Processing({ setSaveClicked, closeButtonRef }) {
  const {
    setPublishClicked,
    trimStartTime,
    trimEndTime,
    userDetails,
    trimmedVideoName, videoDetails
  } = useEditStore();
  const progressRef = useRef(null);
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const dashboardButtonRef = useRef(null);
  const locationPathRef = useRef(null);
  const progressBarRef = useRef(null);
  const handleTrim = async () => {
    const progress = progressRef.current;

    const requestData = {
      inputPath: `${userDetails.userName}/`,
      startTime: `${convertTimeObjectToSeconds(trimStartTime)}`,
      duration: `${
        convertTimeObjectToSeconds(trimEndTime) -
        convertTimeObjectToSeconds(trimStartTime)
      }`,
      filename: userDetails.fileName,
      email: userDetails.email,
      videoName: trimmedVideoName,
    };
    const res = await trim(requestData);

    if (res) {
      //changing to rendering design
      closeButtonRef.current.style.display = "block";
      titleRef.current.innerText = "Video is rendering in cloud";
      titleRef.current.style.left = "22.6%";
      descriptionRef.current.innerText = "You will be notified once its done!";
      descriptionRef.current.style.left = "27.8%";
      dashboardButtonRef.current.style.display = "flex";
      locationPathRef.current.style.display = "flex";
    }

    const chunks = res.split("\n");
    chunks.forEach((chunk, index) => {
      if (chunk === "error") {
        progressBarRef.current.style.visibility = "hidden";
        titleRef.current.innerText = "Error while  rendering in cloud";
        descriptionRef.current.innerText = "Something went wrong!";
        descriptionRef.current.style.left = "33.8%";
        locationPathRef.current.style.display = "none";
      } else {
        if (index === chunks.length - 1) {
          progress.style.width = `96%`;
          setPublishClicked(false);
          deleteVideoFromServer({
            inputPath: `${userDetails.userName}/`,
            videoId: videoDetails.id
          })
            .then(() => console.log("unmount-success"))
            .catch(() => console.log("unmount-err"))
            .finally(() => {
              handleDashboardRedirecting();
            });
          return;
        }
        progress.style.width = `${chunk}`;
      }
    });
  };
  const handleDashboardRedirecting = () => {
    window.open("https://app.flonnect.com", "_blank");
  };
  useEffect(() => {
    if (progressRef.current)
      handleTrim()
        .then(() => console.log())
        .catch(() => console.log());
    return () => setSaveClicked(false);
  }, []);
  useEffect(() => {}, [closeButtonRef]);
  return (
    <ProcessingContent>
      <ProcessingTitle ref={titleRef}>Video is processing</ProcessingTitle>
      <ProgressBar ref={progressBarRef}>
        <Progress ref={progressRef} />
      </ProgressBar>
      <Description ref={descriptionRef}>
        Please don't close this tab or refresh the page!
      </Description>
      <DashboardButton
        ref={dashboardButtonRef}
        onClick={handleDashboardRedirecting}
      >
        <ButtonText>Dashboard</ButtonText>
      </DashboardButton>
      <LocationContainer ref={locationPathRef} processing={true}>
        <LocationPath dashboardLabel={true}>Location:</LocationPath>
        <LocationPath>{"Library > My video Recorordings"}</LocationPath>
      </LocationContainer>
    </ProcessingContent>
  );
}

export default Processing;
