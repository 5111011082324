import {
  Container,
  LeftPlaceHolder,
  RightPlaceHolder,
  TrimLayerContainer,
} from "./TrimLayer.styled";

import { useEffect, useRef } from "react";
import Selection from "./Selection.index";
import useEditStore from "../../../../../store";

function TrimLayer({ mainContainerRef }) {
  const containerRef = useRef(null);
  const { framesContainerRef, filmStripWidthPercent } = useEditStore();
  const leftPlaceHolderRef = useRef(null);
  const rightPlaceHolderRef = useRef(null);
  useEffect(() => {
    if (framesContainerRef?.current && containerRef.current && filmStripWidthPercent) {
      containerRef.current.parentNode.style.width = `${framesContainerRef.current.offsetWidth}px`;
      // setTimeout(()=>{
      // document.querySelector(".sc-hTJqdO.jzNrOQ").style.width=`${framesContainerRef.current.offsetWidth}px`;
      // },500)
    }
  }, [framesContainerRef?.current?.offsetWidth])
  return (
    <TrimLayerContainer>
      <Container ref={containerRef}>
        <LeftPlaceHolder ref={leftPlaceHolderRef} />

        <Selection
          mainContainerRef={mainContainerRef}
          leftPlaceHolderRef={leftPlaceHolderRef}
          rightPlaceHolderRef={rightPlaceHolderRef}
        />
        <RightPlaceHolder ref={rightPlaceHolderRef} />
      </Container>
    </TrimLayerContainer>
  );
}

export default TrimLayer;
