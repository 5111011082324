import { styled } from "styled-components";

export const ZoomContainer = styled.div`
  position: absolute;
  right: 16px;
  top: 24px;
  display: flex;
  gap: 16px;

`;

export const ZoomButton = styled.img`
  width: 12px;
  height: 12px;
  cursor: pointer;
`;

export const RangeInput = styled.input`
  background: linear-gradient(
    to right,
    #9870fa 0%,
    #9870fa 50%,
    #dce1e9 50%,
    #dce1e9 100%
  );
  border-radius: 2.179px;
  width: 122px;
  height: 4.357px;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;

  &::-webkit-slider-thumb {
    width: 12.496px;
    height: 12.496px;
    border-radius: 50%;
    -webkit-appearance: none;
    cursor: pointer;
    background: #fff;
    filter: drop-shadow(0px 0px 5.083333492279053px rgba(0, 0, 0, 0.25));
  }
`;