import { GlobalStyle } from "./globalstyles";
import AppRoute from "./route/AppRoute";
function App() {

  return (
    <div className="App">
      <GlobalStyle />
      <AppRoute />
    </div>
  );
}

export default App;
