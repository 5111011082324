import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Wrapper from "../components/Wrapper/Wrapper.index";
import Pagenotfound from "../components/PageNotFound/pagenotfound";
import AccessDenied from "../components/AccessDeniedPage/index";

function AppRoute() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AccessDenied />} />
        <Route path="/:videoid" element={<Wrapper />} />
        <Route path="/access-denied" element={<AccessDenied />} />
        <Route path="*" element={<Pagenotfound />} />
      </Routes>
    </Router>
  );
}

export default AppRoute;
