import React from "react";
import {
  ContentWrapper,
  PNFButton,
  PNFErrorMsg,
  PageNotFoundLogo,
  PageNotFoundNav,
  PageNotFoundNavLogoImg,
  PageNotFoundWrapper,
} from "./PageNotFound.styled";
import pageNotFoundNavLogo from "./Images/pageNotFoundNavLogo.svg";
import ErrorLogo from "./Images/404Logo.svg";
import { useNavigate } from "react-router-dom";

const Pagenotfound = () => {
  const Navigate = useNavigate();

  return (
    <PageNotFoundWrapper>
      <PageNotFoundNav>
        <PageNotFoundNavLogoImg src={pageNotFoundNavLogo} />
      </PageNotFoundNav>

      <ContentWrapper>
        <PageNotFoundLogo src={ErrorLogo} />
        <PNFErrorMsg>
          Oops, we couldn't find the page, but don't worry, we're working on it!
        </PNFErrorMsg>
        {/* <PNFButton

                    onClick={() => {
                        Navigate("/");
                    }}

                >
                    Back to Homepage
                </PNFButton> */}
      </ContentWrapper>
    </PageNotFoundWrapper>
  );
};

export default Pagenotfound;
