import useEditStore from "../../../../../store";
import {
  convertTimeObjectToSeconds,
  convertSecondsToHMS,
} from "../../../../../helperFunctions/timeConversion";
import { RulerContainer, Unit, TimeLabel, Pointer } from "./Ruler.styled";
import { useEffect } from "react";
function Ruler({ mainContainerRef }) {
  const {
    videoDuration,
    unitsCount,
    framesContainerRef,
    setClickedPixelOnTheRuler,
  } = useEditStore();
  const getUnits = () => {
    if (!videoDuration === 0) return;
    const units = [];
    const durationInSec = convertTimeObjectToSeconds(videoDuration) + 1;
    const iterationValue = durationInSec !== 0 ? durationInSec / unitsCount : 1;
    for (let i = 0; i < durationInSec; i += iterationValue) {
      const { h, m, s, us } = convertSecondsToHMS(i);
      units.push(
        <Unit key={i + "ruler-unit"}>
          <TimeLabel>
            {`${String(h).padStart(2, "0")}:${String(m).padStart(
              2,
              "0"
            )}:${String(s).padStart(2, "0")}`}
          </TimeLabel>
          <Pointer />
        </Unit>
      );
    }
    return units;
  };
  const handleChangeCurrentTimeOnClickingRuler = (e) => {
    const scrolledX = e.clientX - 457 + mainContainerRef?.current?.scrollLeft;
    if ( scrolledX >= 0 && scrolledX <= framesContainerRef.current.offsetWidth)
      setClickedPixelOnTheRuler(scrolledX);
  };
  useEffect(() => {}, [videoDuration, unitsCount]);
  return (
    <RulerContainer onClick={handleChangeCurrentTimeOnClickingRuler}>
      {getUnits()}
    </RulerContainer>
  );
}

export default Ruler;
