import { appClient, ec2Client, trimClient } from "./axios-utils";
export const extractFrames = async (requestData) => {
  try {
    const response = await appClient.post("/api/extract-frames", requestData);
    return response;
  } catch (error) {
    return false;
  }
};

export const getVideoDetails = async (videoId) => {
  try {
    const response = await ec2Client.get(
      `/flonnect/api/video/getEditVideoDetails/${videoId}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteVideoFromServer = async (requestData) => {
  const response = await appClient.post("/api/unmountedit", requestData);
  return response
}

export const trim = async (requestData) => {
  const response = await trimClient.post("/revamp/trim", requestData);
  return response
}