import { MainHeader, LogoContainer, Logo, Text, PublishButton, ButtonText } from "./Header.styled";
import LogoImage from "../../assets/images/editor-logo.png";
import { trim } from "../../helperFunctions/backendCalls";
import useEditStore from "../../store";
function Header() {
  const { userDetails, isPageNotFound, setPublishClicked } = useEditStore()
  const handlePublishVideo = () => {
    setPublishClicked(true)

  }

  return (
    <MainHeader>
      <LogoContainer>
        <Logo src={LogoImage} />
        <Text>editor</Text>
      </LogoContainer>
      <PublishButton enable={!isPageNotFound ? true : false} onClick={handlePublishVideo} >
        <ButtonText>Publish</ButtonText>
      </PublishButton>
    </MainHeader>
  );
}

export default Header;
