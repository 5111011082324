import { create } from "zustand";

const useEditStore = create((set) => ({
  videoUrl: undefined,
  setVideoUrl: (value) => set(() => ({ videoUrl: value })),

  isVideoPlaying: false,
  setVideoPlaying: (value) => set(() => ({ isVideoPlaying: value })),

  videoDuration: { h: "00", m: "00", s: "00" },
  setVideoDuration: (value) => set(() => ({ videoDuration: value })),

  currentTime: { h: "00", m: "00", s: "00" },
  setCurrentTime: (value) => set(() => ({ currentTime: value })),

  isCurrentTimeUpdated: false,
  setCurrentTimeUpdated: (value) =>
    set(() => {
      return { isCurrentTimeUpdated: value };
    }),

  filmStripWidthPercent: 1,
  setFilmStripWidthPercent: (value) =>
    set(() => ({ filmStripWidthPercent: value })),

  unitsCount: 1,
  setUnitsCount: (value) => set(() => ({ unitsCount: value })),

  framesContainerRef: null,
  setFramesContainerRef: (value) => set(() => ({ framesContainerRef: value })),

  trimPortion: { start: 0, end: 0 },
  setTrimPortion: (value) =>
    set((state) => {
      return { trimPortion: { ...state.trimPortion, ...value } };
    }),

  trimStartTime: { h: 0, m: 0, s: 0 },
  setTrimStartTime: (value) => set(() => ({ trimStartTime: value })),

  trimEndTime: { h: 0, m: 0, s: 0 },
  setTrimEndTime: (value) => set(() => ({ trimEndTime: value })),

  isFramesExtracting: true,
  setFramesExtracting: (value) => set(() => ({ isFramesExtracting: value })),

  fileName: null,
  setFileName: (value) => set(() => ({ fileName: value })),

  userDetails: null,
  setUserDetails: (value) =>
    set((state) => ({ userDetails: { ...state.userDetails, ...value } })),

  videoDetails: null,
  setVideoDetails: (value) =>
    set((state) => ({ videoDetails: { ...state.videoDetails, ...value } })),

  isPageNotFound: false,
  setPageNotFound: (value) => set(() => ({ isPageNotFound: value })),

  isPublishClicked: false,
  setPublishClicked: (value) => set(() => ({ isPublishClicked: value })),

  isTrimUpdatedByInput: false,
  setTrimUpdatedByInput: (value) =>
    set(() => ({ isTrimUpdatedByInput: value })),

  trimmedVideoName: "Flonnect file name - edited",
  setTrimmedVideoName: (value) => set(() => ({ trimmedVideoName: value })),

  leftHiddenFramesRef: null,
  setLeftHiddenFramesRef: (value) =>
    set(() => ({ leftHiddenFramesRef: value })),

  rightHiddenFramesRef: null,
  setRightHiddenFramesRef: (value) =>
    set(() => ({ rightHiddenFramesRef: value })),

  clickedPixelOnTheRuler: null,
  setClickedPixelOnTheRuler: (value) =>
    set(() => ({ clickedPixelOnTheRuler: value })),
}));

export default useEditStore;
