import { useEffect, useRef, useState } from "react";
import { ZoomContainer, RangeInput, ZoomButton } from "./Zomm.styled";
import useEditStore from "../../../../../store";
import { convertTimeObjectToSeconds } from "../../../../../helperFunctions/timeConversion";
import zoomOut from "../../../../../assets/images/zoomOut.png";
import zoomIn from "../../../../../assets/images/zoomIn.png";

function Zoom({ mainContainerRef }) {
  const { videoDuration, setUnitsCount, setFilmStripWidthPercent, framesContainerRef } =
    useEditStore();
  const rangeRef = useRef(null);
  const calculateRanges = () => {
    const { width } = mainContainerRef.current.getBoundingClientRect();

    const minFilmStripWidthPercent =
      width / (2 * 59 * convertTimeObjectToSeconds(videoDuration));
    const maxFilmStripWidthPercent = 1;
    const minUnitsCount = width / 2 / 59;
    const maxUnitsCount = Math.ceil(convertTimeObjectToSeconds(videoDuration));
    return {
      filmStrip: {
        min: minFilmStripWidthPercent,
        max: maxFilmStripWidthPercent,
      },
      count: {
        min: minUnitsCount,
        max: maxUnitsCount,
      },
    };
  };

  const setValues = (value) => {
    const { filmStrip, count } = calculateRanges();
    const filmStripValue =
      (value / 100) * (filmStrip.max - filmStrip.min) + filmStrip.min;
    const unitsToDisplay = (value / 100) * (count.max - count.min) + count.min;
    setFilmStripWidthPercent(filmStripValue);
    setUnitsCount(unitsToDisplay);
  };

  const handleInput = (e) => {
    const value = e.target.value;
    e.target.style.background = `linear-gradient(to right, #9870FA 0%, #9870FA ${value}%, #DCE1E9 ${value}%, #DCE1E9 100%)`;
    rangeRef.current.value = value;
    setValues(value);
  };
  useEffect(() => {
    if (mainContainerRef.current) {
      const { width } = mainContainerRef.current.getBoundingClientRect();

      const initialFilmStripWidthPercent =

        (width - 4) / (59 * convertTimeObjectToSeconds(videoDuration));

        setFilmStripWidthPercent(initialFilmStripWidthPercent);
      const initialUnits = width / 59
      setUnitsCount(initialUnits)

      const { filmStrip } = calculateRanges();
      const initialZoomValue = ((initialFilmStripWidthPercent / (filmStrip.max - filmStrip.min)) - filmStrip.min) * 1000
      rangeRef.current.value = initialZoomValue
      rangeRef.current.style.background = `linear-gradient(to right, #9870FA 0%, #9870FA ${initialZoomValue}%, #DCE1E9 ${initialZoomValue}%, #DCE1E9 100%)`
      
    }
  }, [mainContainerRef.current, videoDuration]);

  return (
    <ZoomContainer>
      <ZoomButton src={zoomOut} alt="zoom-out" />
      <RangeInput onInput={handleInput} type="range" ref={rangeRef} />
      <ZoomButton src={zoomIn} alt="zoom-in" />
    </ZoomContainer>
  );
}

export default Zoom;
